import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueComponentMixin } from '~/utils/vue-component';

import { CloseButton } from '~/components/atoms';
import { MediaBackground } from '~/components/molecules';
import VDialogCloseHandler, {
  VDialogCloseHandlerInterface,
} from '~/mixins/VDialogCloseHandler';
import { Colors } from '~/utils/theme/colors';
import { ImageInterface } from '~/components/atoms/image/Image';

import style from './Dialog.scss';

const rootClass = 'czt-dialog';

export interface DialogInterface extends VDialogCloseHandlerInterface {
  backgroundColor?: Colors;
  backgroundImage?: ImageInterface;
  contentClass?: string;
  fullScreen?: boolean;
  hideOverlay?: boolean;
  maxWidth?: number;
  persist?: boolean;
}

@Component({ style })
export default class Dialog
  extends VueComponentMixin<DialogInterface, VDialogCloseHandlerInterface>(
    VDialogCloseHandler
  )
  implements DialogInterface {
  @Prop({ type: String, default: Colors.TEXT_LIGHT })
  public backgroundColor!: Colors;

  @Prop()
  public backgroundImage?: ImageInterface;

  @Prop({ type: String })
  public contentClass?: string;

  @Prop({ type: Boolean, default: false })
  public fullScreen!: boolean;

  @Prop({ type: Boolean, default: false })
  public hideOverlay!: boolean;

  @Prop({ default: 500 })
  public maxWidth!: number;

  @Prop({ type: Boolean, default: false })
  public persist!: boolean;

  protected get light(): boolean {
    return (
      !!this.backgroundImage ||
      (!!this.backgroundColor && this.backgroundColor !== Colors.TEXT_LIGHT)
    );
  }

  public render() {
    const classes = [rootClass];
    if (this.contentClass) {
      classes.push(this.contentClass);
    }
    if (this.fullScreen) {
      classes.push(`${rootClass}--fullscreen`);
    }
    const content: JSX.Element = (
      <v-container
        fluid
        fill-height={this.fullScreen}
        class={{ 'pa-0': this.fullScreen }}
      >
        <v-row no-gutters class={{ 'fill-height': this.fullScreen }}>
          <v-col>
            <CloseButton
              class={`${rootClass}__close`}
              onClose={this.vDialogClose}
              light={this.light}
              solid={this.fullScreen}
            />
            {this.$slots.default}
          </v-col>
        </v-row>
      </v-container>
    );

    return (
      <v-dialog
        content-class={classes.join(' ')}
        {...this.vDialogAttributes}
        max-width={this.fullScreen ? undefined : this.maxWidth}
        hide-overlay={this.hideOverlay}
        fullscreen={this.fullScreen}
      >
        {(() => {
          if (this.backgroundImage) {
            return (
              <MediaBackground image={this.backgroundImage}>
                {content}
              </MediaBackground>
            );
          }
          return (
            <v-sheet
              class={{ [rootClass]: true, 'fill-height': this.fullScreen }}
              color={this.backgroundColor}
            >
              {content}
            </v-sheet>
          );
        })()}
      </v-dialog>
    );
  }

  @Watch('persist', { immediate: true })
  protected resetPersist() {
    if (this.persist && this.setPersistent) {
      this.setPersistent();
    } else if (!this.persist && this.setNonpersistent) {
      this.setNonpersistent();
    }
  }
}
