import Vue from 'vue';
import { Module, Action, Mutation } from 'vuex-module-decorators';

import AbstractModule from './AbstractModule';
import {
  isMeatspaceDetailResponse,
  VenueFinderLanguage,
  MeatspaceDetailRequestBody,
  isMeatspaceSearchResponse,
  isMeatspaceSearchResponsePayload,
  isMeatspaceVenueDetail,
  isMeatspaceVenueSearchItem,
} from '~/utils/venueFinder';
import {
  ExtendedVenueDetail,
  MeatspaceListRequestBody,
  MeatspaceVenueSearch,
} from '~/utils/venueFinder/types';

interface SetResourceCommit {
  id: number;
  resource: ExtendedVenueDetail | null;
}

export interface CreateNewBookingRequestPayload {
  venueId: number;
  event: {
    date: string;
    dateTo?: string;
    dateFlexible?: boolean;
    type?: string;
    pax: number;
    note?: string;
    length?: number;
  };
  client: {
    email: string;
    name?: string;
    company?: string;
    phone: string;
    lang?: VenueFinderLanguage;
  };
  source: string;
  test: boolean;
}

interface CreateNewBookingResponse {
  success: boolean;
  payload: {
    id: number;
    source: string;
    date_created: string;
    date_responded: string;
    venue_id: number;
    status: number;
    hash: string;
    hashid: string;
    log: string[];
    budget_min: number;
    budget_max: number;
    fee: number;
    fee_status: number;
    customer_id: number;
    created_by: string | null;
  };
}

function isCreateNewBookingResponse(
  data: any
): data is CreateNewBookingResponse {
  return (
    data &&
    typeof data.success === 'boolean' &&
    typeof data.payload === 'object' &&
    typeof data.payload.id === 'number'
  );
}

@Module({
  name: 'VenueModule',
  stateFactory: true,
  namespaced: true,
})
export default class VenueModule extends AbstractModule {
  public bookingLoading: boolean = false;

  public currentId: number | null = null;

  public loading: boolean = true;

  protected promiseCache: { [key: number]: Promise<void> } = {};

  protected resourceCache: { [key: number]: ExtendedVenueDetail | null } = {};

  protected createVenueBookingPromise: Promise<boolean> | null = null;

  public get resource(): ExtendedVenueDetail | null {
    const resource =
      this.currentId && this.resourceCache.hasOwnProperty(this.currentId)
        ? this.resourceCache[this.currentId]
        : null;
    return resource;
  }

  @Action({ rawError: true })
  public loadVenueById({ id }: { id: number }): Promise<void> {
    if (this.promiseCache.hasOwnProperty(id)) {
      this.setCurrentId(id);
      return this.promiseCache[id];
    }

    this.resourceLoading(true);

    const options: MeatspaceDetailRequestBody = {
      options: {
        imagesWidth: 1920,
        noCache: true,
        markdown: true,
      },
    };

    const promise = this.$api
      .venueFinder()
      .meatSpaceWrapperPost(
        `venues/get/${id}`,
        JSON.stringify(options),
        VenueFinderLanguage.english
      )
      .then((response) => {
        if (
          !isMeatspaceDetailResponse(response) ||
          !isMeatspaceVenueDetail(response.payload)
        ) {
          this.setResource({ id, resource: null });
          return;
        }
        const venue = response.payload;
        const subvenues: MeatspaceVenueSearch[] = [];
        const requestBody: MeatspaceListRequestBody = {
          limit: 100,
          offset: 0,
          search: {
            parent: id,
          },
          options: {
            imagesCount: 1,
            includeSubvenues: true,
          },
        };
        return this.$api
          .venueFinder()
          .meatSpaceWrapperPost(
            'venues/list',
            JSON.stringify(requestBody),
            VenueFinderLanguage.english
          )
          .then((subvenuesResponse) => {
            if (
              isMeatspaceSearchResponse(subvenuesResponse) &&
              isMeatspaceSearchResponsePayload(subvenuesResponse.payload)
            ) {
              subvenues.push(
                ...subvenuesResponse.payload.venues.filter(
                  isMeatspaceVenueSearchItem
                )
              );
            }
          })
          .finally(() => {
            this.setResource({ id, resource: { ...venue, subvenues } });
          });
      })
      .catch(() => {
        this.setResource({ id, resource: null });
        return;
      })
      .finally(() => {
        this.setCurrentId(id);
        this.resourceLoading(false);
      });

    this.setPromise({ id, promise });
    return promise;
  }

  @Action({ rawError: true })
  public createVenueBooking({
    data,
    reCaptchaToken,
  }: {
    data: CreateNewBookingRequestPayload;
    reCaptchaToken: string;
  }): Promise<boolean> {
    if (this.createVenueBookingPromise) {
      return this.createVenueBookingPromise;
    }

    this.setBookingLoading(true);

    const promise = this.$api
      .venueFinder()
      .meatSpaceWrapperPost(
        `bookings/create`,
        JSON.stringify(data),
        VenueFinderLanguage.english,
        reCaptchaToken
      )
      .then((response) => {
        if (!isCreateNewBookingResponse(response)) {
          return false;
        }
        return response.success;
      })
      .catch(() => {
        return false;
      })
      .finally(() => {
        this.setBookingLoading(false);
        this.setCreateVenueBookingPromise(null);
      });

    this.setCreateVenueBookingPromise(promise);
    return promise;
  }

  @Mutation
  protected setResource(data: SetResourceCommit): void {
    this.resourceCache[data.id] = data.resource;
    Vue.set(this.resourceCache, data.id, data.resource);
  }

  @Mutation
  protected setPromise({
    id,
    promise,
  }: {
    id: number;
    promise: Promise<void>;
  }): void {
    this.promiseCache[id] = promise;
    Vue.set(this.promiseCache, id, promise);
  }

  @Mutation
  protected resourceLoading(value: boolean): void {
    this.loading = value;
  }

  @Mutation
  protected setCurrentId(value: number | null): void {
    this.currentId = value;
  }

  @Mutation
  protected setCreateVenueBookingPromise(promise: Promise<boolean> | null) {
    this.createVenueBookingPromise = promise;
  }

  @Mutation
  protected setBookingLoading(state: boolean) {
    this.bookingLoading = state;
  }
}
