import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { IconText } from '~/components/molecules';
import { VueComponent } from '~/utils/vue-component';
import WinterDataCarousel, { Webcam } from './WinterDataGallery';

import style from './WinterData.scss';
import { mdiSki, mdiSkiWater, mdiSnowflake } from '~/utils/iconPaths';

export interface WinterInfoData {
  slopeLength: number;
  slopeLenghtOpen: number;
  open: boolean;
  liftsOpen: boolean;
  liftNumber: number;
  liftNumberOpen: number;
  snowHeight: number;
  snowType: number;
  webcam: Webcam[];
}

interface WinterDataProps {
  data: WinterInfoData;
}

const rootClass = 'czt-winter-data';

@Component({ style })
export default class WinterData extends VueComponent<WinterDataProps>
  implements WinterDataProps {
  @Prop({ required: true })
  public data!: WinterInfoData;

  public render() {
    return (
      <div class={rootClass}>
        <Headline underscore align={Align.LEFT} level={4}>
          {this.$t('app.winter.snowReport')}
        </Headline>
        <div>
          <IconText iconMargin='5px' iconSize={1.75} icon={mdiSnowflake}>
            {this.$t('app.winter.snow', {
              snow: this.data.snowHeight < 0 ? 0 : this.data.snowHeight,
              type: this.$t(
                `app.winter.snowTypeTranslation.${this.data.snowType}`
              ),
            })}
          </IconText>
        </div>
        <br />
        <Headline underscore align={Align.LEFT} level={4}>
          {this.$t('app.winter.slopes')}
        </Headline>
        <div>
          <IconText iconMargin='5px' iconSize={1.75} icon={mdiSki}>
            <span
              class={`${rootClass}__diode${
                this.data.slopeLenghtOpen > 0 ? ` ${rootClass}__diode--on` : ''
              }`}
            />
            {`${this.data.slopeLenghtOpen / 1000} / ${this.data.slopeLength /
              1000}`}
            {' km '}
            {this.$t('app.winter.open')}
          </IconText>
        </div>
        <br />
        <Headline underscore align={Align.LEFT} level={4}>
          {this.$t('app.winter.lifts')}
        </Headline>
        <div>
          <IconText iconMargin='5px' iconSize={1.75} icon={mdiSkiWater}>
            <span
              class={`${rootClass}__diode${
                this.data.liftNumberOpen > 0 ? ` ${rootClass}__diode--on` : ''
              }`}
            />
            {this.data.liftNumberOpen} / {this.data.liftNumber}{' '}
            {this.$t('app.winter.open')}
          </IconText>
        </div>
        {this.data.webcam.length && [
          <br />,
          <Headline underscore align={Align.LEFT} level={4}>
            {this.$t('app.winter.webcam')}
          </Headline>,
          <WinterDataCarousel items={this.data.webcam} />,
        ]}
      </div>
    );
  }
}
