import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';
import ItineraryPlanDay, { PlanDay } from './ItineraryPlanDay';
import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';

interface ItineraryPlanProps {
  data: PlanDay[];
}

const rootClass = 'czt-itinerary-plan';

@Component
export default class ItineraryPlan extends VueComponent<ItineraryPlanProps>
  implements ItineraryPlanProps {
  @Prop()
  public data!: PlanDay[];

  public render() {
    const classes = [rootClass, 'czt-spacer'];

    return (
      <div class={classes.join(' ')}>
        <v-container id={`anchor_itinerary`}>
          <v-row justify='center'>
            <v-col cols={12}>
              <Headline level={2} underscore align={Align.LEFT}>
                {this.$t('app.itinerary.title')}
              </Headline>
              {this.data.map((plan, i) => (
                <ItineraryPlanDay
                  planDay={plan}
                  key={plan.title + i.toString()}
                />
              ))}
            </v-col>
          </v-row>
        </v-container>
      </div>
    );
  }
}
