export default {
  app: {
    itinerary: {
      title: 'Itinerary',
      transportationInfoTitle: 'Next transit point',
      car: 'Car',
      publicTransport: 'Public transport',
      generalInfo: 'General information',
      filterLabel: 'Filter by tag',
    },
  },
};
