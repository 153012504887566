import { Component, Prop } from 'vue-property-decorator';
import { Headline } from '~/components/atoms';
import { CztWidgets, Widget } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import TransportInfo from './TransportInfo';
import { Align } from '~/components/atoms/headline/Headline';

import Carousel from '~/components/organisms/carousel/Carousel';
import isCarousel from '~/components/organisms/carousel/isCarousel';
import { IconText, Link } from '~/components/molecules';
import { LinkTarget } from '~/utils/molecules';
import HtmlRenderer from '../../base/HtmlRenderer';

export interface PlanStop {
  title: string;
  information: string;
  gps?: {
    lat: number;
    long: number;
  };
  widgets: Widget[];
  carDistance: string;
  carTime: string;
  publicTransportTime: string;
  nextStopTitle?: string;
}

interface ItineraryPlanStopProps {
  planStop: PlanStop;
}

const rootClass = 'czt-itinerary-plan-stop';

@Component
export default class ItineraryPlanStop
  extends VueComponent<ItineraryPlanStopProps>
  implements ItineraryPlanStopProps {
  @Prop({ required: true })
  public planStop!: PlanStop;

  protected get carousels() {
    return this.planStop.widgets.filter(isCarousel);
  }

  public render() {
    const classes = [rootClass, 'pt-3'];

    const transportInfoTitle = [
      this.$t('app.itinerary.transportationInfoTitle'),
    ];

    if (this.planStop.nextStopTitle) {
      transportInfoTitle.push(this.planStop.nextStopTitle);
    }

    return (
      <v-expansion-panel>
        <v-expansion-panel-header>
          <Headline level={4} align={Align.LEFT}>
            {this.planStop.title}
          </Headline>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <section class={classes.join(' ')}>
            <Headline align={Align.LEFT} level={3} underscore>
              {this.$t('app.itinerary.generalInfo')}
            </Headline>
            <div class='czt-richtext'>
              <HtmlRenderer content={this.planStop.information} />
              {this.planStop.gps && [
                <br />,
                <br />,
                <Link
                  url={`https://www.google.com/maps/search/?api=1&query=${this.planStop.gps.lat},${this.planStop.gps.long}`}
                  target={LinkTarget.BLANK}
                >
                  <IconText icon={['common', 'MapPin']}>
                    {this.$t('app.place.showOnExternalMaps')}
                  </IconText>
                </Link>,
              ]}
            </div>
            {this.carousels.map((car, i) => (
              <Carousel
                className={CztWidgets.CAROUSEL}
                items={car.items}
                title={car.title}
                isBottomSpacingCollapsed
                isTopSpacingCollapsed
                anchorId={car.anchorId}
                key={i}
                contained
              />
            ))}
            <TransportInfo
              carDistance={this.planStop.carDistance}
              carTime={this.planStop.carTime}
              publicTransportTime={this.planStop.publicTransportTime}
              title={transportInfoTitle.join(' - ')}
            />
          </section>
        </v-expansion-panel-content>
      </v-expansion-panel>
    );
  }
}
