import { Component, Prop } from 'vue-property-decorator';
import { LinkTarget, link } from '~/utils/molecules';
import { VueComponent } from '~/utils/vue-component';

import style from './Button.scss';

export interface ButtonInterface {
  buttonStyle?: string;
  disabled?: boolean;
  forcedLocale?: string;
  label?: string;
  loading?: boolean;
  onClick?: (e: Event) => void;
  small?: boolean;
  submit?: boolean;
  thin?: boolean;
  target?: LinkTarget;
  url?: string;
}

export enum Style {
  PRIMARY = '',
  PRIMARY_LIGHT = 'czt-button--light',
  SECONDARY = 'czt-button--secondary',
  SECONDARY_LIGHT = 'czt-button--secondary czt-button--light',
  TERTIARY = 'czt-button--tertiary',
  TERTIARY_LIGHT = 'czt-button--tertiary czt-button--light',
}

const rootClass = 'czt-button';

@Component({
  style,
})
export default class Button extends VueComponent<ButtonInterface>
  implements ButtonInterface {
  @Prop({ default: false, type: Boolean })
  public disabled!: boolean;

  @Prop({ default: false, type: Boolean })
  public loading!: boolean;

  @Prop({ default: Style.PRIMARY, type: String })
  public buttonStyle!: Style;

  @Prop({ default: false, type: Boolean })
  public small!: boolean;

  @Prop({ default: false, type: Boolean })
  public submit!: boolean;

  @Prop({ default: false, type: Boolean })
  public thin!: boolean;

  @Prop({ default: '', type: String })
  public url!: string;

  @Prop({ default: '', type: String })
  public target!: LinkTarget;

  @Prop({ type: String })
  public forcedLocale?: string;

  protected get href(): string | undefined {
    return link.isInternal(this.url) ? undefined : this.url;
  }

  protected get to(): string | undefined {
    return link.isInternal(this.url)
      ? link.createInternal(this.url, this.forcedLocale || this.$i18n.locale)
      : undefined;
  }

  public render() {
    const classes = [rootClass, this.buttonStyle];
    if (this.thin) {
      classes.push(rootClass + '--thin');
    }
    if (this.small) {
      classes.push(rootClass + '--small');
    }
    return (
      <v-btn
        class={classes.join(' ')}
        disabled={this.disabled}
        loading={this.loading}
        href={this.href}
        to={this.to?.toLowerCase()}
        target={this.target}
        onClick={this.handleClick}
        type={this.submit ? 'submit' : 'button'}
      >
        {this.$slots.default}
      </v-btn>
    );
  }

  protected handleClick(e: Event): void {
    if (this.disabled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }
    if (this.url.toString().indexOf('#') === 0) {
      const anchor = document.querySelector(this.url.toString());
      if (anchor && anchor instanceof HTMLElement) {
        this.$vuetify.goTo(anchor);
      } else {
        this.$vuetify.goTo('#anchor_content');
      }
    }
    this.$emit('click');
    return;
  }
}
