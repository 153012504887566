import { Component, Emit, Prop, Ref } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline, Button, InputField } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import Dialog from '~/components/organisms/dialog/Dialog';

import style from './NewsletterPopUp.scss';
import { Colors } from '~/utils/theme/colors';
import HtmlRenderer from '../base/HtmlRenderer';
import { InputFieldState } from '~/components/atoms/inputField/InputField';
import { LocaleMessage } from 'vue-i18n';
import { Link } from '~/components/molecules';
import { PageLoader } from '.';
import ReturnButton from '~/components/atoms/returnButton/ReturnButton';
import NewsletterModule, {
  getNewsletterKey,
  Newsletter,
} from '~/app/core/store/modules/NewsletterModule';
import NewsletterButton from '../newsletter/NewsletterButton';
import { getModule } from 'vuex-module-decorators';
import setSubscribedToNewsletter from '~/utils/newsletter/setSubscribedToNewsletter';
import RecaptchaWrapper from '../recaptchaWrapper/RecaptchaWrapper';
import { Target } from '~/utils/molecules/link';

const rootClass = 'czt-newsletter-popup';

interface NewsletterPopUpInterface {
  active: boolean;
  onClose?: () => void;
}

@Component({ style })
export default class NewsletterPopUp
  extends VueComponent<NewsletterPopUpInterface>
  implements NewsletterPopUpInterface {
  @Prop({ required: true })
  public active!: boolean;

  @Ref('newsletterInput')
  public readonly newsletterInput!: InputField;

  protected email: string = '';

  protected error: string | LocaleMessage = '';

  protected success: string | LocaleMessage = '';

  protected showNewsletterList: boolean = false;

  protected selectedNewsletters: string[] = [];

  protected persist: boolean = true;

  public get state(): InputFieldState {
    if (this.subscribing) {
      return InputFieldState.LOADING;
    }
    if (this.error !== '') {
      return InputFieldState.ERROR;
    }
    if (this.success !== '') {
      return InputFieldState.SUCCESS;
    }
    return InputFieldState.DEFAULT;
  }

  protected get newsletterStore() {
    return getModule(NewsletterModule, this.$store);
  }

  protected get newslettersLoading() {
    return this.newsletterStore.loading;
  }

  protected get newsletters() {
    return this.newsletterStore.newsletters;
  }

  protected get subscribing() {
    return this.newsletterStore.subscribing;
  }

  public render() {
    return (
      <Dialog
        contentClass={rootClass}
        active={this.active}
        onCloseDialog={this.handleClose}
        backgroundColor={Colors.PRIMARY}
        persist={this.persist}
        maxWidth={650}
      >
        <v-fade-transition group hide-on-leave>
          {!this.showNewsletterList ? (
            <v-row key={`${rootClass}__email`}>
              <v-col cols='12'>
                <Headline
                  underscore
                  light
                  align={Align.CENTER}
                  class='mb-0 mt-3'
                >
                  {this.$t('app.newsletter.title')}
                </Headline>
              </v-col>
              <v-col class='text-center' cols='12'>
                <HtmlRenderer
                  content={this.$t('app.newsletter.topText')}
                  light
                />
              </v-col>
              <v-col cols='12'>
                <v-form
                  class={`${rootClass}__form`}
                  onSubmit={this.handleSubmit}
                >
                  <v-row no-gutters>
                    <v-col>
                      <InputField
                        errorMessage={this.error}
                        placeholder={this.$t('app.newsletter.inputPlaceholder')}
                        v-model={this.email}
                        state={this.state}
                        successMessage={this.success}
                        type='email'
                        ref='newsletterInput'
                        required
                        light
                      />
                    </v-col>
                    <v-col class='text-center' cols='12' md='auto'>
                      <Button
                        submit
                        loading={this.subscribing}
                        style='height: 56px !important'
                      >
                        {this.$t('app.newsletter.button')}
                      </Button>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col class='text-center' cols='12'>
                <HtmlRenderer
                  content={this.$t('app.newsletter.bottomText')}
                  light
                />
              </v-col>
            </v-row>
          ) : this.success === '' ? (
            <div key={`${rootClass}__selection`}>
              <ReturnButton
                class={`${rootClass}__return`}
                light
                onReturn={this.closeNewsletterList}
              />
              <v-row>
                <v-col cols='12'>
                  <Headline
                    underscore
                    align={Align.CENTER}
                    light
                    class='mb-0 mt-3'
                  >
                    {this.$t('app.newsletter.dialog.headline')}
                  </Headline>
                </v-col>
                <v-col cols='12'>{this.createNewsletterList()}</v-col>
                <v-col cols='12' class={`${rootClass}__content`}>
                  <i18n tag='div' path='app.newsletter.dialog.notice'>
                    <Link
                      url={this.$t('app.url.privacy')}
                      target={Target.BLANK}
                    >
                      {this.$t('app.newsletter.dialog.link.privacy')}
                    </Link>
                  </i18n>
                  <i18n
                    class='mt-2'
                    tag='div'
                    path='app.newsletter.dialog.company'
                  >
                    <Link
                      url={this.$t('app.url.controller')}
                      target={Target.BLANK}
                    >
                      {this.$t('app.newsletter.dialog.link.controller')}
                    </Link>
                    <Link url={this.$t('app.url.web')} target={Target.BLANK}>
                      {this.$t('app.newsletter.dialog.link.web')}
                    </Link>
                  </i18n>
                </v-col>
                <v-col class='text-center' cols='12'>
                  <RecaptchaWrapper
                    {...{
                      scopedSlots: {
                        default: (scope: any) => {
                          return (
                            <Button
                              disabled={this.selectedNewsletters.length < 1}
                              loading={this.subscribing}
                              {...{
                                on: scope.on,
                              }}
                            >
                              {this.$t('app.newsletter.button')}
                            </Button>
                          );
                        },
                      },
                    }}
                    onRecaptchaCallback={this.sendSubscription}
                  />
                </v-col>
              </v-row>
            </div>
          ) : (
            <div key={`${rootClass}__success`}>
              <v-row>
                <v-col cols='12'>
                  <Headline
                    underscore
                    light
                    align={Align.CENTER}
                    class='mb-0 mt-3'
                  >
                    {this.$t('app.newsletter.title')}
                  </Headline>
                </v-col>
                <v-col class='text-center' cols='12'>
                  <HtmlRenderer content={this.success} light />
                </v-col>
              </v-row>
            </div>
          )}
        </v-fade-transition>
      </Dialog>
    );
  }

  protected handleSubmit(e: Event) {
    e.preventDefault();
    if (!this.newsletterInput.checkValidity()) {
      this.error = this.$t('app.newsletter.validityError');
      return;
    }
    this.error = '';
    this.openNewsletterList();
  }

  protected openNewsletterList() {
    this.newsletterStore.getNewsletters({});
    this.showNewsletterList = true;
  }

  protected closeNewsletterList() {
    this.showNewsletterList = false;
  }

  protected getNewsletters() {
    if (this.newsletters.length > 0) {
      return this.newsletters.map((newsletter) =>
        this.createNewsletterButton(newsletter)
      );
    }
  }

  protected createNewsletterList() {
    if (this.newslettersLoading) {
      return <PageLoader />;
    }
    if (this.newsletters.length < 1) {
      return <div>{this.$t('app.newsletter.newsletterError')}</div>;
    }
    return (
      <v-item-group multiple v-model={this.selectedNewsletters}>
        <v-row no-gutters>{this.getNewsletters()}</v-row>
      </v-item-group>
    );
  }

  protected createNewsletterButton(newsletter: Newsletter) {
    return (
      <v-col class='pa-1' cols='12' md='6' xl='4' key={newsletter.guid}>
        <NewsletterButton newsletter={newsletter} light />
      </v-col>
    );
  }

  protected sendSubscription(token: string) {
    this.newsletterStore
      .subscribe({
        email: this.email,
        newsletters: this.selectedNewsletters,
        reCaptchaToken: token,
      })
      .then((response) => {
        if (response) {
          this.error = '';
          this.success = this.$t('app.newsletter.success');
          this.persist = false;
          setSubscribedToNewsletter();
        } else {
          this.error = this.$t('app.newsletter.serverError');
          this.closeNewsletterList();
        }
      })
      .catch(() => {
        this.error = this.$t('app.newsletter.serverError');
        this.closeNewsletterList();
      });
  }

  @Emit('close')
  protected handleClose() {
    return;
  }
}
