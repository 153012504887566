import { Component, Prop, Ref } from 'vue-property-decorator';

import { ImageInterface } from '~/components/atoms/image/Image';
import { VideoInterface } from '~/components/atoms/video/Video';
import { ImageFilterMixin, ImageFilterMixinInterface } from '~/mixins';
import { ThemeColors, ThemeRatios } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';

import style from './MediaBackground.scss';
import { ImageWrapper, Link, VideoWrapper } from '..';
import { Sizes } from '../imageWrapper/ImageWrapper';
import { BasePage } from '~/app/core/apiClient/api';
import ImageFilterTypeEnum = BasePage.ImageFilterTypeEnum;
import Icon, { IconName } from '~/components/atoms/icons/Icon';

interface MediaBackgroundInterface extends ImageFilterMixinInterface {
  image: ImageInterface;
  overlayColor?: ThemeColors;
  isFirst?: boolean;
  lazy?: boolean;
  lazyTimeout?: number;
  ratio?: ThemeRatios;
  showVideoControls?: boolean;
  sizes?: Sizes[];
  video?: VideoInterface;
}

const rootClass: string = 'czt-image-background';

@Component({
  style,
})
export default class MediaBackground
  extends VueComponentMixin<
    MediaBackgroundInterface,
    ImageFilterMixinInterface
  >(ImageFilterMixin)
  implements MediaBackgroundInterface {
  @Prop({ required: true })
  public image!: ImageInterface;

  @Prop({ default: ThemeRatios.auto })
  public ratio!: ThemeRatios;

  @Prop()
  public overlayColor?: ThemeColors;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop()
  public lazy?: boolean;

  @Prop()
  public lazyTimeout?: number;

  @Prop()
  public video?: VideoInterface;

  @Prop()
  public sizes?: Sizes[];

  @Prop({ default: false, type: Boolean })
  public showVideoControls!: boolean;

  @Ref('mediaWrapper')
  protected readonly mediaWrapper!: ImageWrapper | VideoWrapper;

  protected mutationObserver: MutationObserver | null = null;

  protected muteButton: IconName = ['common', 'Mute'];

  protected playButton: IconName = ['common', 'Play'];

  public mounted() {
    if (this.ratio === ThemeRatios.auto) {
      this.mutationObserver = new MutationObserver(() => {
        this.mediaWrapper.setCover();
      });
      this.mutationObserver.observe(this.$el, {
        characterData: true,
        childList: true,
        subtree: true,
      });
    }
  }

  public destroyed() {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  public render() {
    const imageWrapperClasses = [`${rootClass}__image`];

    if (!this.overlayColor) {
      switch (this.imageFilter) {
        case ImageFilterTypeEnum.Darken:
          imageWrapperClasses.push(`${rootClass}__image--darken`);
          break;
        case ImageFilterTypeEnum.Softdarken:
          imageWrapperClasses.push(`${rootClass}__image--soft-darken`);
          break;
      }
    }

    return (
      <v-sheet
        class={
          `${rootClass} ` +
          (this.ratio === ThemeRatios.auto ? `${rootClass}--height-auto` : '')
        }
      >
        {this.renderOverlay()}
        <div class={imageWrapperClasses.join(' ')}>
          {(() => {
            if (this.video) {
              return (
                <VideoWrapper
                  isFirst={this.isFirst}
                  video={this.video}
                  ratio={this.ratio}
                  poster={this.image}
                  ref='mediaWrapper'
                  onEnded={() => {
                    this.playButton = ['common', 'Play'];
                  }}
                  onMuted={() => {
                    this.muteButton = ['common', 'Mute'];
                  }}
                  onPaused={() => {
                    this.playButton = ['common', 'Play'];
                  }}
                  onPlaying={() => {
                    this.playButton = ['common', 'Pause'];
                  }}
                  onUnmuted={() => {
                    this.muteButton = ['common', 'Unmute'];
                  }}
                />
              );
            }
            return (
              <ImageWrapper
                image={this.image}
                isFirst={this.isFirst}
                lazy={this.lazy}
                lazyTimeout={this.lazyTimeout}
                ratio={this.ratio}
                ref='mediaWrapper'
                sizes={this.sizes}
              />
            );
          })()}
        </div>
        <div class={`${rootClass}__content`}>{this.$slots.default}</div>
        {this.renderVideoControls()}
      </v-sheet>
    );
  }

  protected renderOverlay() {
    if (this.overlayColor) {
      return (
        <div
          class={`${rootClass}__overlay`}
          style={`background-color: ${this.overlayColor}`}
        />
      );
    }
    return;
  }

  protected renderVideoControls() {
    if (this.video && this.showVideoControls) {
      return (
        <div class={`${rootClass}__video-controls`}>
          <button
            onClick={this.controlPlayback}
            class={`${rootClass}__video-controls__button`}
          >
            <Icon name={this.playButton} />
          </button>
          <button
            onClick={this.controlAudio}
            class={`${rootClass}__video-controls__button`}
          >
            <Icon name={this.muteButton} />
          </button>
          <v-spacer />
          {this.video.src && (
            <Link
              url={this.video.src}
              class={`${rootClass}__video-controls__button`}
            >
              <Icon name={['social', 'YoutubeSimple']} />
            </Link>
          )}
        </div>
      );
    }
  }

  protected controlAudio(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.mediaWrapper instanceof VideoWrapper) {
      this.mediaWrapper.toggleMute();
    }
  }

  protected controlPlayback(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.mediaWrapper instanceof VideoWrapper) {
      this.mediaWrapper.togglePlayback();
    }
  }
}
