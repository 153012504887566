import deepmerge from 'deepmerge';
import { LocaleMessages } from 'vue-i18n';

import CommonLocalization from './common';
import CookiesLocalization from './cookies';
import MapsLocalization from './maps';
import NewsletterLocalization from './newsletter';
import PanoramaLocalization from './panorama';
import SafeTravelsLocalization from './safeTravels';
import SearchLocalization from './search';
import TradeOffersLocalization from './tradeOffers';
import UserLocalization from './user';
import WinterLocalization from './winter';
import WeatherLocalization from './weather';

const mergedLocalizations: LocaleMessages = deepmerge.all([
  CommonLocalization,
  CookiesLocalization,
  MapsLocalization,
  NewsletterLocalization,
  PanoramaLocalization,
  SafeTravelsLocalization,
  SearchLocalization,
  TradeOffersLocalization,
  UserLocalization,
  WinterLocalization,
  WeatherLocalization,
]) as LocaleMessages;

export default mergedLocalizations;
