import { Component, Prop } from 'vue-property-decorator';
import { SpaVoucherInfo } from '~/app/core/apiClient/api';
import { Button, Headline } from '~/components/atoms';
import { Style } from '~/components/atoms/button/Button';
import { Align, LineColor } from '~/components/atoms/headline/Headline';
import { Colors } from '~/utils/theme/colors';
import { VueComponent } from '~/utils/vue-component';
import HtmlRenderer from '../base/HtmlRenderer';

export interface VoucherData {
  title: string;
  description: string;
  listTitle: string;
  vouchers: VoucherMonth[];
  label: string;
  url: string;
}

export function createVoucherData(data: SpaVoucherInfo): VoucherData {
  const vouchers: (VoucherMonth | null)[] =
    data.vouchersAvailability?.map(
      ({ count, limit, month }): VoucherMonth | null => {
        if (!count || !limit || !month) {
          return null;
        }
        return {
          count,
          limit,
          month,
        };
      }
    ) || [];
  return {
    title: data.title?.trim() || '',
    description: data.shortDescription?.trim() || '',
    label: data.buttonTitle?.trim() || '',
    listTitle: data.vouchersListTitle?.trim() || '',
    url: data.buttonUrl?.trim() || '',
    vouchers: vouchers.filter(isVoucherMonth) || [],
  };
}

function isVoucherMonth(data: any): data is VoucherMonth {
  return (
    data &&
    typeof data === 'object' &&
    typeof data.count === 'number' &&
    typeof data.limit === 'number' &&
    typeof data.month === 'string'
  );
}

interface VoucherMonth {
  count: number;
  limit: number;
  month: string;
}

interface VoucherInterface {
  data: VoucherData;
}

@Component
export default class Voucher extends VueComponent<VoucherInterface> {
  @Prop({ required: true })
  public data!: VoucherData;

  public render() {
    return (
      <v-sheet color={Colors.PRIMARY}>
        <v-container fluid>
          <v-row justify='center'>
            {this.data.title && (
              <v-col cols={12}>
                <Headline
                  underscore
                  lineColor={LineColor.RED}
                  align={Align.CENTER}
                  level={3}
                  light
                  class='mb-0'
                >
                  {this.data.title}
                </Headline>
              </v-col>
            )}
            {this.data.description && (
              <v-col cols={12} md={11}>
                <HtmlRenderer
                  content={this.data.description}
                  light
                  side
                  class='text-center text-md-left'
                />
              </v-col>
            )}
            {this.data.vouchers.length > 0 && (
              <v-col cols='auto' md={11}>
                {this.data.listTitle && (
                  <Headline light level={4} class='mb-3'>
                    {this.data.listTitle}
                  </Headline>
                )}
                <v-sheet class='pa-3 pb-2' color='white'>
                  <v-row no-gutters>
                    {(() => {
                      return this.data.vouchers.map((voucher) => {
                        return (
                          <v-col cols={12} class='mb-1'>
                            <v-row no-gutters justify='space-between'>
                              <v-col cols='auto'>{`${voucher.month}: `}</v-col>
                              <v-col cols='auto'>{`${voucher.count} / ${voucher.limit}`}</v-col>
                            </v-row>
                          </v-col>
                        );
                      });
                    })()}
                  </v-row>
                </v-sheet>
              </v-col>
            )}
            {this.data.url && (
              <v-col cols={12} md={11} class='text-center'>
                <Button buttonStyle={Style.PRIMARY} url={this.data.url}>
                  {this.data.label || this.$t('app.common.moreInfo')}
                </Button>
              </v-col>
            )}
          </v-row>
        </v-container>
      </v-sheet>
    );
  }
}
