export default {
  app: {
    itinerary: {
      title: 'Itinerarios',
      transportationInfoTitle: 'Próximo punto de tránsito',
      car: 'Coche',
      publicTransport: 'Transporte público',
      generalInfo: 'Información general',
      filterLabel: 'Filtrar por etiqueta',
    },
  },
};
