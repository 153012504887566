import { Component, Prop } from 'vue-property-decorator';
import HeadManagement from '~/mixins/HeadManagement';
import { VueComponentMixin } from '~/utils/vue-component';
import { Banner, Breadcrumbs } from '~/components/organisms';
import { Button, Headline } from '~/components/atoms';
import WidgetRenderer from '~/components/templates/base/WidgetRenderer';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import TopLayerImage from '~/components/templates/common/TopLayerImage';
import imageHeroDefault from '~/assets/images/hero_default.jpg';
import { TradeOfferPage } from '~/utils/views';
import createSeoTags, { createJsonLd } from '~/utils/views/createSeoTags';
import { getUrlFromPath } from '~/app/core/router';
import { sidebarColumns } from '~/utils/sidebarColumns';
import { Align } from '~/components/atoms/headline/Headline';
import { IconText, Link } from '~/components/molecules';
import { Widget } from '~/utils/views/widgets';
import { Style } from '~/components/atoms/button/Button';
import TranslationVoting from '~/components/templates/common/TranslationVoting';
import Advertisement from '~/components/organisms/advertisement/Advertisement';
interface TradeOfferView {
  data?: TradeOfferPage;
}

@Component
export default class TradeOffer
  extends VueComponentMixin<TradeOfferView, {}>(HeadManagement)
  implements TradeOfferView {
  @Prop()
  public data!: TradeOfferPage;

  protected get showSideColumn(): boolean {
    return (
      (this.data.showRightColumn &&
        Array.isArray(this.data.widgets.right) &&
        this.data.widgets.right.length > 0) ||
      this.data.banners.length > 0
    );
  }

  protected get hasInfo(): boolean {
    return !!(
      this.data.address ||
      this.data.email ||
      this.data.facebook ||
      this.data.phone ||
      this.data.twitter ||
      this.data.webUrl ||
      this.data.web2Url
    );
  }

  protected get firstWidget(): Widget | null {
    const widgets = [...this.data.widgets.main];
    return this.data.firstWidgetAsHero ? widgets.shift() || null : null;
  }

  protected get mainWidgets(): Widget[] {
    const widgets = [...this.data.widgets.main];
    if (this.data.firstWidgetAsHero) {
      widgets.shift();
    }
    return widgets;
  }

  protected get title() {
    return this.data.title;
  }

  public headTags(): string {
    return (
      createSeoTags({
        title: this.data.title,
        description: this.data.perex,
        canonicalUrl: getUrlFromPath(this.data.canonicalPath, this.$router),
        image: this.data.image,
        imagesToPreload: this.data.image ? [this.data.image] : undefined,
        path: this.data.canonicalPath,
        mutations: this.data.availableCultures,
      }) +
      createJsonLd({
        image: this.data.imagesToPreload,
        title: this.data.title,
        created: this.data.created,
        modified: this.data.modified,
      })
    );
  }

  public render() {
    return (
      <v-container fluid class='pa-0' key={this.data.guid}>
        {this.renderHero()}

        {!this.data.hideBreadcrumbs && (
          <v-container>
            <v-row>
              <v-col cols={12}>
                <Breadcrumbs breadcrumbs={this.data.breadcrumbs} />
              </v-col>
            </v-row>
          </v-container>
        )}

        {(() => {
          if (this.data.content || this.data.perex) {
            return (
              <v-container id={`anchor_content`}>
                <v-row justify='center'>
                  <v-col cols={12} md={this.showSideColumn ? 7 : 12} lg={8}>
                    {(() => {
                      const content: JSX.Element[] = [];
                      if (this.data.perex) {
                        content.push(
                          <div class='czt-perex'>
                            <HtmlRenderer content={this.data.perex} />
                          </div>
                        );
                      }
                      if (this.data.content) {
                        content.push(
                          <div class='czt-richtext'>
                            <HtmlRenderer content={this.data.content} />
                          </div>
                        );
                      }
                      if (this.data.showAutomatedTranslationVoting) {
                        content.push(
                          <TranslationVoting
                            locale={this.$i18n.locale}
                            message={this.data.automatedTranslationMessage}
                            pageId={this.data.guid}
                          />
                        );
                      }
                      return content;
                    })()}
                  </v-col>
                  {this.showSideColumn && this.renderSideContent()}
                </v-row>
              </v-container>
            );
          } else if (this.showSideColumn) {
            return (
              <v-container>
                <v-row>
                  <v-col cols={12} class='py-0'>
                    {this.renderSideContent()}
                  </v-col>
                </v-row>
              </v-container>
            );
          }
        })()}
        {this.data.widgets.before.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.before} />
        )}
        {this.mainWidgets.length > 0 && (
          <WidgetRenderer widgets={this.mainWidgets} />
        )}
        {this.data.widgets.after.length > 0 && (
          <WidgetRenderer widgets={this.data.widgets.after} />
        )}
        {this.hasInfo && (
          <v-container class='mb-10'>
            <v-sheet color='primary' class='pt-5'>
              <v-row justify='center'>
                <v-col cols={12} md={this.showSideColumn ? 7 : 12} lg={8}>
                  <Headline
                    class='mb-0'
                    underscore
                    level={3}
                    align={Align.LEFT}
                    light
                  >
                    {this.$t('app.common.moreInfo')}
                  </Headline>
                </v-col>
                <v-col cols={12} md={this.showSideColumn ? 7 : 12} lg={8}>
                  <v-row justify='center'>
                    {(() => {
                      const info: JSX.Element[] = [];
                      if (this.data.address) {
                        info.push(
                          <Headline level={5} align={Align.LEFT} light>
                            {this.$t('app.tradeOffers.address')}
                          </Headline>,
                          <HtmlRenderer content={this.data.address} light />
                        );
                      }
                      if (this.data.phone) {
                        info.push(
                          <Headline level={5} align={Align.LEFT} light>
                            {this.$t('app.tradeOffers.phone')}
                          </Headline>,
                          <Link light url={`tel:${this.data.phone}`}>
                            {this.data.phone}
                          </Link>
                        );
                      }
                      return (
                        info.length > 0 && (
                          <v-col cols={12} sm={6} md={4}>
                            {info.map((element) => {
                              return <div class='mb-2'>{element}</div>;
                            })}
                          </v-col>
                        )
                      );
                    })()}
                    {(() => {
                      const info: JSX.Element[] = [];
                      if (this.data.email) {
                        info.push(
                          <Headline level={5} align={Align.LEFT} light>
                            {this.$t('app.tradeOffers.email')}
                          </Headline>,
                          <Link light url={`mailto:${this.data.email}`}>
                            {this.data.email}
                          </Link>
                        );
                      }
                      if (this.data.facebook || this.data.twitter) {
                        info.push(
                          <Headline level={5} align={Align.LEFT} light>
                            {this.$t('app.tradeOffers.social')}
                          </Headline>
                        );
                        if (this.data.facebook) {
                          info.push(
                            <IconText icon={['social', 'Facebook']}>
                              <Link light url={`${this.data.facebook}`}>
                                Facebook
                              </Link>
                            </IconText>
                          );
                        }
                        if (this.data.twitter) {
                          info.push(
                            <IconText icon={['social', 'Twitter']}>
                              <Link light url={`${this.data.twitter}`}>
                                Twitter
                              </Link>
                            </IconText>
                          );
                        }
                      }
                      return (
                        info.length > 0 && (
                          <v-col cols={12} sm={6} md={4}>
                            {info.map((element) => {
                              return <div class='mb-2'>{element}</div>;
                            })}
                          </v-col>
                        )
                      );
                    })()}
                    {(() => {
                      const info: JSX.Element[] = [];
                      if (this.data.webUrl || this.data.web2Url) {
                        info.push(
                          <Headline level={5} align={Align.LEFT} light>
                            {this.$t('app.tradeOffers.web')}
                          </Headline>
                        );
                        if (this.data.webUrl) {
                          info.push(
                            <Link light url={`${this.data.webUrl}`}>
                              {this.data.webUrl}
                            </Link>
                          );
                        }
                        if (this.data.web2Url) {
                          info.push(
                            <Link light url={`${this.data.web2Url}`}>
                              {this.data.web2Url}
                            </Link>
                          );
                        }
                      }
                      return (
                        info.length > 0 && (
                          <v-col cols={12} sm={6} md={4}>
                            {info.map((element) => {
                              return <div class='mb-2'>{element}</div>;
                            })}
                          </v-col>
                        )
                      );
                    })()}
                  </v-row>
                </v-col>
              </v-row>
            </v-sheet>
          </v-container>
        )}
      </v-container>
    );
  }

  protected renderHero(): JSX.Element {
    let events = {};
    if (!this.data.anchorTitle) {
      events = {
        on: {
          click: this.anchorClick,
        },
      };
    }
    return this.firstWidget ? (
      <WidgetRenderer widgets={[this.firstWidget]} isFirst />
    ) : (
      <Banner
        backgroundImage={{
          src: this.data.image ? this.data.image : imageHeroDefault,
          alt: `${this.data.title}`,
        }}
        isFirst
        imageFilter={this.data.imageFilter}
        clickable={!this.data.anchorTitle}
        showArrow={!this.data.anchorTitle}
        {...events}
        title={`${this.data.title}`}
        titleLevel={1}
        titlePosition={`${this.data.titlePosition}`}
      >
        <Headline level={3} light>
          {this.data.subtitle}
        </Headline>
        {(() => {
          if (this.data.anchorTitle) {
            return (
              <Button
                class='my-3'
                buttonStyle={Style.PRIMARY_LIGHT}
                onClick={this.anchorClick}
              >
                {this.data.anchorTitle}
              </Button>
            );
          }
        })()}
        <TopLayerImage pageData={this.data} />
      </Banner>
    );
  }

  protected renderSideContent() {
    return (
      <v-col {...{ attrs: sidebarColumns }}>
        <div class='czt-side-content'>
          <WidgetRenderer widgets={this.data.widgets.right} side />
          {this.data.banners.map((banner) => (
            <Advertisement data={banner} />
          ))}
        </div>
      </v-col>
    );
  }

  protected anchorClick() {
    const anchorId = this.data.anchor || 'content';
    const anchor = document.querySelector(`#anchor_${anchorId}`);
    if (anchor && anchor instanceof HTMLElement) {
      this.$vuetify.goTo(anchor);
    } else {
      this.$vuetify.goTo('#anchor_content');
    }
  }
}
